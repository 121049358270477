@keyframes fadeInLetter {
    0% { opacity: 0; }
    100% { opacity: 1;}
}

@keyframes fadeInDotsMobile {
    0% { opacity: 0; width: 0px; }
    100% { opacity: 1; width: 10px; }
}

@keyframes fadeOutWhitespaceMobile {
    0% { opacity: 1; width: 10px; }
    100% { opacity: 0; width: 0; }
}

@keyframes fadeInDotsDesktop {
    0% { opacity: 0; width: 0px; }
    100% { opacity: 1; width: 24px; }
}

@keyframes fadeOutWhitespaceDesktop {
    0% { opacity: 1; width: 24px; }
    100% { opacity: 0; width: 0; }
}