@import '../../styling/variables.scss';
@import '../../styling/keyframes.scss';

.app {
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;

    &__title {
        color: $black;
        font-size: 3.2rem;
        line-height: 1;
        letter-spacing: 2px;

        @media (min-width: $breakpoint-tablet) {
            font-size: 8.2rem;
        }
    }

    &__word {
        display: inline-block;
    }

    &__letter {
        opacity: 0;
        display: inline-block;
        will-change: opacity;

        &--whitespace {
            opacity: 1;
            width: 10px;
            will-change: opacity, width;
            animation: fadeOutWhitespaceMobile 1.0s 3.0s normal forwards ease;

            @media (min-width: $breakpoint-tablet) {
                width: 24px;
                animation: fadeOutWhitespaceDesktop 1.0s 3.0s normal forwards ease;
            }
        }

        &--1 {
            animation: fadeInLetter 0.5s 0.25s normal forwards ease;
        }

        &--2 {
            animation: fadeInLetter 0.55s 0.35s normal forwards ease;
        }

        &--3 {
            animation: fadeInLetter 0.60s 0.45s normal forwards ease;
        }

        &--4 {
            animation: fadeInLetter 0.65s 0.55s normal forwards ease;
        }

        &--5 {
            animation: fadeInLetter 0.70s 0.65s normal forwards ease;
        }

        &--6 {
            animation: fadeInLetter 0.75s 0.75s normal forwards ease;
        }

        &--7 {
            width: 0;
            will-change: opacity, width;
            animation: fadeInDotsMobile 1.0s 3.0s normal forwards ease;
            
            @media (min-width: $breakpoint-tablet) {
                animation: fadeInDotsDesktop 1.0s 3.0s normal forwards ease;
            }
        }

        &--8 {
            animation: fadeInLetter 0.85s 0.95s normal forwards ease;
        }

        &--9 {
            animation: fadeInLetter 0.90s 1.05s normal forwards ease;
        }

        &--10 {
            animation: fadeInLetter 0.95s 1.15s normal forwards ease;
        }

        &--11 {
            animation: fadeInLetter 1s 1.25s normal forwards ease;
        }

        &--12 {
            animation: fadeInLetter 1.05s 1.35s normal forwards ease;
        }

        &--13 {
            animation: fadeInLetter 1.10s 1.45s normal forwards ease;
        }

        &--14 {
            animation: fadeInLetter 1.15s 1.55s normal forwards ease;
        }

        &--15 {
            width: 0;
            animation: fadeInDotsMobile 1.0s 3.0s normal forwards ease;

            @media (min-width: $breakpoint-tablet) {
                animation: fadeInDotsDesktop 1.0s 3.0s normal forwards ease;
            }
        }

        &--16 {
            animation: fadeInLetter 1.25s 1.75s normal forwards ease;
        }

        &--17 {
            will-change: opacity, width;
            animation: fadeInLetter 1.30s 1.85s normal forwards ease;
        }

        &--18 {
            animation: fadeInLetter 1.35s 1.95s normal forwards ease;
        }
    }
}
