@import './variables.scss';

* {
    box-sizing: border-box;
}

html {
    font-size: 62.5%;
    font-family: 'Georgia', serif;
}

body {
    font-size: 1.6rem;

    ::selection {
        background-color: $black;
        color: $white;
    }
}